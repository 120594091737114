import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import CoursePT from '../../components/CoursePT/CoursePT';
import CourseEN from '../../components/CourseEN/CourseEN';

import './Course.scss';

function Course() {
  const { id: course } = useParams();
  const userId = localStorage.getItem('userId');
  localStorage.setItem('courseId', course);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container course">
    {
      userId ? (
        <div>
          {
            (course === '5f83853c539a937ed63fbf2c') ?
              <CoursePT /> 
            : (
              (course === '60b35888a6e53a00171c7efb') ?
                <CourseEN /> 
              : <Redirect to='/login' />
            )
          }
        </div>
      ) : (
        <Redirect to='/login' />
      )
    }
      
    </div>
  );
}

export default Course;