import React from 'react';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { Grid, TextField, Button, Select, MenuItem, InputLabel } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';

import api from '../../services/api';

import './EditCourseDate.scss';

class EditCourseDate extends React.Component {
  constructor() {
    super();

    this.state = {
      name: '',
      dateCourse: new Date(),
      time: ''
    };
  }
  
  componentDidMount() {
    const id = this.props.match.params.id;

    api.get(`/user-courses-course/${id}`)
      .then(response => {
        const course = response.data._doc;
        const userCourse = response.data.userCourse;
        this.setState({
          id: userCourse._id,
          name: course?.name,
          calendar: course?.calendar,
        });
    });
  }

  TextFieldComponent(props){
    return <TextField {...props} disabled={true} />
  }

  async handleSubmit() {
    const { 
      id,
      dateCourse,
      time
    } = this.state;

    if (time && dateCourse) {
      const hours = time.split(':');
      dateCourse.setHours(parseInt(hours[0]), parseInt(hours[1]));

      try {
        await api.put(`/user-courses-date/`, {
          id,
          dateCourse
        });

        this.props.history.push('/dashboard');
        window.location.reload(false);
      } catch (error) {
        alert('Edit user error!!!');
      }
    }
  }

  formatDate(date) {
    const formDate = new Date(date);
    const day = formDate.getDate();
    const year = formDate.getFullYear();
    const month = formDate.getMonth() + 1;

    return day + '/' + month + '/' + year;
  }

  render() {
    const { 
      name, 
      dateCourse,
      calendar,
      time
    } = this.state;

    return (
      <div className="container edit">
        <h1>Course Schedule</h1>

        <form
          onSubmit={(e) => { 
            e.preventDefault();
            this.handleSubmit();
          }}
          className="form-container" 
          noValidate 
          autoComplete="off"
        >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                Course: <b>{ name }</b>
              </Grid>
              <Grid item xs={12}>
                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <KeyboardDatePicker
                      disablePast
                      margin="normal"
                      id="date-picker-dialog"
                      label="Date Course"
                      format="dd/MM/yyyy"
                      value={dateCourse}
                      onChange = {
                        date => this.setState({
                          dateCourse: date
                        })
                      }
                      shouldDisableDate={
                        date => {
                          const weekdays = [];

                          calendar.map( item => {
                            weekdays.push(this.formatDate(item?.day));
                          });

                          return !weekdays.includes(this.formatDate(date));
                        }
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      TextFieldComponent={this.TextFieldComponent}
                    />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                 <Grid className="form-select" item xs={4}>
                    <InputLabel>Time</InputLabel>
                    <Select
                      className="form-input"
                      required
                      labelId="select-label"
                      value={time}
                      onChange={e => this.setState({ time: e.target.value })}
                    >
                      {
                        calendar?.map((item, index) => (
                          this.formatDate(dateCourse) === this.formatDate(item?.day) ? (
                            <MenuItem key={index} value={item?.hour}>{item?.hour}</MenuItem>
                          ) : <MenuItem></MenuItem>
                        ))
                      }
                    </Select>
                  </Grid>
              </Grid>
            </Grid>
           
            <Grid item xs={12}>
              <Button 
                className="btn-save float-r"
                type="submit"
              >
                Save
              </Button>
              <Link 
                className="btn-cancel float-r"
                to='/dashboard'
              >
                Cancel
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>
    )
  };
}

export default EditCourseDate;