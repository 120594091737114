const vimeo = 'https://player.vimeo.com/video';

// Modulo 1
const videoIntro = `${vimeo}/560166157`;
const videoModulo11 = `${vimeo}/560167275`;
// Modulo 2
const videoModulo21 = `${vimeo}/560168523`;
const videoModulo22 = `${vimeo}/560350591`;
// Modulo 3
const videoModulo31 = `${vimeo}/557141426`;
const videoModulo32 = `${vimeo}/557217113`;
const videoModulo33 = `${vimeo}/557145300`;
// Modulo 4
const videoModulo41 = `${vimeo}/557251280`;
const videoModulo42 = `${vimeo}/557164537`;
const videoModulo43 = `${vimeo}/557166574`;
// Modulo 5
const videoModulo51 = `${vimeo}/557276641`;
// Modulo 6
const videoModulo61 = `${vimeo}/557278667`;
const videoModulo62 = `${vimeo}/557380063`;

const SIDEMNU = [
  {
    id: 1,
    title: 'Module 1',
    items: [{
        id: 1,
        link: '1.0',
        title: 'Introduction',
        video: videoIntro,
        hidden: false,
        watched: false
      }, {
        id: 2,
        link: '1.1',
        title: '1.1 Food Safety',
        video: videoModulo11,
        hidden: true,
        watched: false
      }, {
        id: 5,
        link: '1.2',
        title: 'Review',
        hidden: true,
        watched: false
    }],
    hidden: false,
    watched: false,
    enabled: true
  }, {
    id: 2,
    title: 'Module 2',
    items: [{
        id: 1,
        link: '2.1',
        title: '2.1 Food Hygiene',
        video: videoModulo21,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '2.2',
        title: '2.2 Personal Hygiene',
        video: videoModulo22,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '2.3',
        title: 'Review',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 3,
    title: 'Module 3',
    items: [{
      id: 1,
        link: '3.1',
        title: '3.1 Food Hazards',
        video: videoModulo31,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '3.2',
        title: '3.2 Food Poisoning',
        video: videoModulo32,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '3.3',
        title: '3.3 Bacterial Growth',
        video: videoModulo33,
        hidden: true,
        watched: false
      }, {
        id: 4,
        link: '3.4',
        title: 'Review',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 4,
    title: 'Module 4',
    items: [{
        id: 1,
        link: '4.1',
        title: '4.1 Transport, Distribution and Storage',
        video: videoModulo41,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '4.2',
        title: '4.2 Food Preparation',
        video: videoModulo42,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '4.3',
        title: '4.3 Food Service',
        video: videoModulo43,
        hidden: true,
        watched: false
      }, {
        id: 4,
        link: '4.4',
        title: 'Review',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 5,
    title: 'Module 5',
    items: [{
        id: 1,
        link: '5.1',
        title: '5.1 HACCP',
        video: videoModulo51,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '5.2',
        title: 'Review',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 6,
    title: 'Module 6',
    items: [{
        id: 1,
        link: '6.1',
        title: '6.1 Facilities Layout',
        video: videoModulo61,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '6.2',
        title: '6.2 Pest Control',
        video: videoModulo62,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '6.3',
        title: 'Review',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 7,
    title: 'FINAL TEST',
    items: [{
        id: 1,
        link: '7.1',
        title: 'Test',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }
];

export default SIDEMNU;