import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCourses } from '../../redux/course/course.selectors';
import { selectLanguage } from '../../redux/language/language.selectors';

import EN_DATA from '../../data/language/english.data';
import PT_DATA from '../../data/language/portuguese.data';

import CourseCart from '../../components/CourseCart/CourseCart';

import './Services.scss';

const Services = ({ courses, language }) => {
  // getting language text
  const text = language === 'EN' ? EN_DATA.sections.services : PT_DATA.sections.services;

  const online = courses ? courses.filter( course => course.type === 'online' ) : '';
  const facetoface = courses ? courses.filter(course => course.type !== 'online') : '';

  return (
    <div 
      id="services" 
      className="container txt-center services"
    >
      <h1>{ text[0] }</h1>

      {
        language !== 'EN' ? (
          <div>
            <h2>{ text[2] }</h2>
            <section className = "services-cards line-bottom">
              {
                (
                  facetoface ?
                  facetoface.map(course => (
                    (course.active === true) ? (
                      // PT-BR Course
                      (course._id === '5f9987a1f02c0d0017ec57f6' || course._id === '61b4fb459f146a00182881f0') ?
                      '' :
                      < CourseCart 
                        key = {course._id}
                        course = {course}
                        courses = {courses}
                        language = {language}
                      />
                    ) : ''
                  )) :
                  ''
                )
              }
            </section>
          </div>
        ): (
          <div>
             <h2>{ text[2] }</h2>
            <section className = "services-cards line-bottom">
              {
                (
                  facetoface ?
                  facetoface.map(course => (
                    (course.active === true) ? (
                      // EN Course
                      (course._id === '61bcbadd20808400185c5268') ?
                      '' :
                       < CourseCart 
                        key = {course._id}
                        course = {course}
                        courses = {courses}
                        language = {language}
                       />
                    ) : ''
                  )) :
                  ''
                )
              }
            </section>
          </div>
        )
      }

      {
        language !== 'EN' ? (
          <div>
            <h2>{ text[1] }</h2>
            <section className="services-cards">
              {(
                online ?
                  online.map( course => (
                    ( course.active === true ) ?
                      (
                        // PT-BR Course
                        (course._id === '5f83853c539a937ed63fbf2c') ?
                          < CourseCart 
                            key = {course._id}
                            course = {course}
                            courses = {courses}
                            language = {language}
                          />
                        : ''
                      ) : ''
                    )
                  )
                : ''
              )}
            </section>
          </div>  
        ) : (
          <div>
            <h2>{ text[1] }</h2>
            <section className="services-cards">
              {(
                online ?
                  online.map( course => (
                    ( course.active === true ) ?
                      (
                        // EN Course
                        (course._id === '60b35888a6e53a00171c7efb') ?
                          < CourseCart 
                            key = {course._id}
                            course = {course}
                            courses = {courses}
                            language = {language}
                          />
                        : ''
                      ) : ''
                    )
                  )
                : ''
              )}
            </section>
          </div>
        )
      }
    </div>
  )
};

const mapStateToProps = createStructuredSelector({
  courses: selectCourses,
  language: selectLanguage
});

export default connect(mapStateToProps)(Services);