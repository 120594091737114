import React from 'react';
import { connect } from 'react-redux';

import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import { clearItemFromCart } from '../../redux/cart/cart.actions';

import './CheckoutItem.scss';

const formatDate = (date) => {
  const formDate = new Date(date);
  let day = formDate.getDate();
  let month = formDate.getMonth() + 1;
  const year = formDate.getFullYear();

  if (day.toString().length === 1) {
    day = '0' + day;
  }

  if (month.toString().length === 1) {
    month = '0' + month;
  }

  return day + '/' + month + '/' + year;
}

const CheckoutItem = ({ cartItem, clearItem }) => {
  const { dateSelected } = cartItem;
  const dateCourse = dateSelected ? `${formatDate(dateSelected.day)} - ${dateSelected.hour}` : '';

  return (
    <div className="checkout-item">
      <span className="name">Course: { cartItem.name } { dateCourse }</span>

      {(
        !(cartItem.price === 0) ? 
        <span 
          className="remove-button" 
          onClick={
            () => {
              clearItem(cartItem);
            }
          }
        >
          <RemoveCircleOutlineOutlinedIcon />
        </span>
        : ''
      )}

      <span className="price">€{ cartItem.price }</span>
    </div>
  )
};

const mapDispatchToProps = dispatch => ({
    clearItem: item => dispatch(clearItemFromCart(item))
});

export default connect(null, mapDispatchToProps)(CheckoutItem);