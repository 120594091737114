import axios from "axios";

const dev = "http://localhost:3333";
const prod = "https://headstartcourses-app.herokuapp.com/";
const server = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: prod,
});

export default api;
