import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useHistory } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import { Grid, Button, RadioGroup, FormLabel, FormControlLabel, Radio } from '@material-ui/core';

import QUESTIONS from '../../../data/questions/questionsEN.data';

import { selectCurrentUser } from '../../../redux/user/user.selectors';

import api from '../../../services/api';

import './CourseENForm.scss';

function CourseForm(props) {
  const { moduleId, currentUser  } = props;
  const [level, setLevel] = useState(() => parseInt(localStorage.getItem('courseLevel')));
  const [questions, setQuestions] = useState(QUESTIONS);
  const [questionsModule, setQuestionsModule] = useState(
    () => {
      if (moduleId === 7) {
        let exam = [];
        let question = [];
        questions.modules.map( (question) => {
          question.questions.map( option => exam.push(option));
        });
        exam = exam.sort(() => Math.random() - 0.5);
        exam.questions = exam.slice(0, 15);

        // hack to change the id because to find
        // the answer we have to pass the question id
        exam.questions.map( (option, index) => {
          question.push({
            answer: option.answer,
            id: index + 1,
            name: option.name,
            options: option.options,
            question: option.question
          });
        });
        exam.questions = question;
        
        return exam;
      }

      return questions.modules.find(module => module.id === moduleId);
    }
  );
  const [question1, setQuestion1] = useState();
  const [question2, setQuestion2] = useState();
  const [question3, setQuestion3] = useState();
  const [question4, setQuestion4] = useState();
  const [question5, setQuestion5] = useState();
  const [question6, setQuestion6] = useState();
  const [question7, setQuestion7] = useState();
  const [question8, setQuestion8] = useState();
  const [question9, setQuestion9] = useState();
  const [question10, setQuestion10] = useState();
  const [question11, setQuestion11] = useState();
  const [question12, setQuestion12] = useState();
  const [question13, setQuestion13] = useState();
  const [question14, setQuestion14] = useState();
  const [question15, setQuestion15] = useState();
  const [score, setScore] = useState(false);
  const [disabled, setDisabled] = useState(() => level > 7 ? true : false);
  const [isApproved, setIsApproved] = useState(false);
  const [tried, setTried] = useState(false);
  const history = useHistory();

  const handleSubmit = event => {
    event.preventDefault();
    setTried(true);

    let score = 0;
    score += question1 === findAnswer(1) ? 1 : 0;
    score += question2 === findAnswer(2) ? 1 : 0;
    score += question3 === findAnswer(3) ? 1 : 0;
    score += question4 === findAnswer(4) ? 1 : 0;
    score += question5 === findAnswer(5) ? 1 : 0;

    if ( moduleId === 7) {
      score += question6 === findAnswer(6) ? 1 : 0;
      score += question7 === findAnswer(7) ? 1 : 0;
      score += question8 === findAnswer(8) ? 1 : 0;
      score += question9 === findAnswer(9) ? 1 : 0;
      score += question10 === findAnswer(10) ? 1 : 0;
      score += question11 === findAnswer(11) ? 1 : 0;
      score += question12 === findAnswer(12) ? 1 : 0;
      score += question13 === findAnswer(13) ? 1 : 0;
      score += question14 === findAnswer(14) ? 1 : 0;
      score += question15 === findAnswer(15) ? 1 : 0;

      score = Math.ceil( score * 100 / 15);
    } else {
      score = Math.ceil( score * 100 / 5);
    }
    setScore(score);

    if ( score > 70 || score === 70 ) {
      setIsApproved(true);
      upgradeLevel();
    } else {
       setIsApproved(false);
    }
  }

  const upgradeLevel = async () => {
    const { currentUser } = props;

    if ( moduleId > level || moduleId === level ) {
      await api.put(`/user-courses`, {
        user_id: currentUser.id,
        course_id: '60b35888a6e53a00171c7efb',
        level: level + 1,
        module: 1
      }).then(async response => {
          if ((level + 1) === 8) {
            setDisabled(true);

            history.push('/congratulations');
            await api.post('/send-certificate', {
              name: currentUser.name,
              email: currentUser.email
            });
          }
      });
    }
  }

  const findAnswer = (option) => {
     const answer = questionsModule.questions.find(question => question.id === option);
     return answer.answer;
  }

  const handleChange = (value, id) => {
    if ( moduleId < 8 ) {
      setTried(false);

      switch (id) {
        case 1:
          setQuestion1(value);
          break;
        case 2:
          setQuestion2(value);
          break;
        case 3:
          setQuestion3(value);
          break;
        case 4:
          setQuestion4(value);
          break;
        case 5:
          setQuestion5(value);
          break;
        case 6:
          setQuestion6(value);
          break;
        case 7:
          setQuestion7(value);
          break;
        case 8:
          setQuestion8(value);
          break;
        case 9:
          setQuestion9(value);
          break;
        case 10:
          setQuestion10(value);
          break;
        case 11:
          setQuestion11(value);
          break;
        case 12:
          setQuestion12(value);
          break;
        case 13:
          setQuestion13(value);
          break;
        case 14:
          setQuestion14(value);
          break;
        case 15:
          setQuestion15(value);
          break;
        default:
          break;
      }
    }
  };

  const handleValue = id => {
    switch (id) {
      case 1:
        return question1;
        break;
      case 2:
        return question2;
        break;
      case 3:
        return question3;
        break;
      case 4:
        return question4;
        break;
      case 5:
        return question5;
        break;
      case 6:
        return question6;
        break;
      case 7:
        return question7;
        break;
      case 8:
        return question8;
        break;
      case 9:
        return question9;
        break;
      case 10:
        return question10;
        break;
      case 11:
        return question11;
      break;
      case 12:
        return question12;
      break;
      case 13:
        return question13;
      break;
      case 14:
        return question14;
      break;
      case 15:
        return question15;
      break;
      default:
        break;
    }
  };

  const handleAnswer = (answer, value, id) => {
    if ( tried ) {
      if ( answer === value ) {
        return value === findAnswer(id) ? 'correct' : 'wrong';
      }
    }

    return '';
  }

  return (
    <div className="form-course-content"> 
      <form
        onSubmit={handleSubmit}
        className="form-container"
        noValidate 
        autoComplete="off"
      >
        <Grid container spacing={4}>
          
          <Grid item xs={12} sm={12}>
            <Grid item xs={12}>
              <h1>{moduleId === 7 ? 'Exam' : `Review Module ${moduleId}`}</h1>
            </Grid>
            {
              questionsModule ? 
                questionsModule?.questions.map( question => (
                  <Grid
                    className="form-question"
                    key={question.id} 
                    item xs={12}
                  >
                    <FormLabel
                      component="legend">
                      { question?.question }
                    </FormLabel>
                    <RadioGroup
                      name={question?.name}
                      value={handleValue(question.id)}
                      onChange={e => {
                        handleChange(e.target.value, question.id);
                      }}
                    >
                      {
                        question?.options.map( (option, index) => (

                          option.text ? (
                            <FormControlLabel
                              key={ index } 
                              className={`
                                form-question-input ${handleAnswer(handleValue(question.id), option.value, question.id)}
                              `}
                              value={ option.value }
                              control={<Radio />} 
                              label={ option.text }
                              disabled={disabled}
                            />
                          ) : ''
                        ))
                      }
                    </RadioGroup>
                  </Grid>
                )) 
              : ''
            }
          </Grid>
          {
            !isApproved && tried ? (
              <Grid item xs={12}>
                <Alert severity="error">Sorry, try it again! Grade: { score }%</Alert>
              </Grid>
            ) : ''
          }
          {
            isApproved && tried ? (
              <Grid item xs={12}>
                <Alert severity="success">Congratulations, keep studying hard! Grade: { score }%</Alert>
              </Grid>
            ) : ''
          }
          <Grid className="btns" item xs={12}>
            <Button 
              className="btn-save"
              type="submit"
              disabled={disabled}
            >
              Validate
            </Button>
            {
              isApproved && tried && moduleId < 7 ? (
                <Button
                  className = "btn-go"
                  type = "button"
                  onClick={() => window.location.reload()}
                >
                    Next 
                </Button>
              ) : ''
            }
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

export default connect(mapStateToProps, null)(CourseForm);