const PT_DATA = {
  menu: [
    'HOME',
    'SERVIÇOS',
    'SOBRE NÓS',
    'GALERIA',
    'CONTATO',
    'LOGIN',
    'LOGOUT',
    'ÁREA DO ALUNO',
  ],
  sections: {
    message: [
      'Aviso importante!',
      'Estamos funcionando regularmente e de acordo com todas as diretrizes do governo. Por ser considerado educação e um treinamento profissionalizante que só pode ser entregue presencialmente, nosso curso de barista tem permissão para continuar com as aulas presenciais.'
    ],
    services: [
      'SERVIÇOS',
      'Cursos Online',
      'Cursos Presenciais',
      'Veja mais'
    ],
    about: [
      'SOBRE NÓS',
      'A Head Start Courses é uma empresa com sede em Dublin formada por Bartira Augelli, profissional renomada e focada na área de treinamentos na Irlanda, incluindo cursos de Food Safety (HACCP - Level 1) e Barista, além de oferecer suporte para o desenvolvimento de currículo dos estudantes. Ambos são oferecidos no ambiente presencial ou online, em inglês ou português, contribuindo especialmente àqueles que precisam de um conteúdo eficaz e claro para conseguirem um emprego na Irlanda.',
      'Com uma linguagem simples, dinâmica e envolvente, a Head Start Courses auxilia através do treinamento adequado, com o objetivo de desenvolver a confiança necessária para que o estudante se destaque no mercado de trabalho, contribuindo, assim, com sua jornada pessoal e profissional.',
      'MISSÃO',
      'Entregar conhecimento de forma leve e eficaz, desenvolvendo pessoal e profissionalmente o aluno.',
      'VISÃO',
      'Ser referência na formação de profissionais destaques no setor alimentício da Irlanda, contribuindo com a vida dos imigrantes e com o país.',
      'VALORES',
      'Honestidade, Integridade, Respeito e Inclusão',
      'SOBRE A PROFISSIONAL',
      '“Meu negócio surgiu quando percebi que meu conhecimento e experiência poderiam gerar um  impacto positivo na vida das pessoas, principalmente intercambistas como eu. Desde então, minha alegria é trabalhar com o que amo e saber que contribuo para o start de muitos profissionais.”',
      '(BARTIRA)',
      'Bartira é mestre graduada pela Trinity College Dublin (TCD) em "MSc in Entrepreneurship", tendo como parte da sua pesquisa e dissertação de mestrado a necessidade de treinamentos de segurança alimentar no mercado Irlandês.',
      'Bartira também possui treinamento com certificação em "Advanced HACCP Design and Validation based on Risk Assessment(Level 6)" pelo QQI, Bacharel pela International College Dublin(ICD) - "BA(Honours) Business Studies" - em negócios e treinamento, com certificação pela School of Education - Trinity College Dublin(TCD) em "Mentoring Training".',
      '',
      'Empreendedora, é fundadora de dois negócios em Dublin: uma estilosa cafeteria dentro da British Studies Centres que foi fechada por conta da pandemia, e um curso de barista(Barista Course By Bartira) no qual vem orgulhosamente atualmente entregando cursos e treinamentos de barista há 7 anos na Irlanda. Bartira também ministra palestras, treinamentos e workshops em escolas de idiomas e agências de intercâmbio na Irlanda e dedica parte do seu tempo em trabalhos sociais através de treinamentos de barista para adolescentes que fazem parte de um grupo socioeconômico tradicionalmente desafiador, em áreas carentes de Dublin.Faz parte e atua como mentora no "Career LEAP" Work Readiness Training Programme, um programa criado pela Trinity College Dublin(TCD) e negócios locais com o propósito de minimizar o desemprego entre jovens.',
      'Aperte o play e comece hoje sua jornada profissional!'
    ],
    contact: [
      'CONTATO',
      'Preencha o formulário abaixo. Retornaremos assim que possível.',
      'Nome',
      'Email',
      'Telefone',
      'Assunto',
      'Dúvidas',
      'Assistência Técnica',
      'Comentários',
      'Outros',
      'Mensagem',
      'Enviar',
      'Localização',
      'Você pode encontrar a Head Start Courses em:',
      'Seja muito bem-vindo! É um prazer ter você conosco!'
    ],
    login: [
      'Email',
      'Senha',
      'Criar nova conta',
      'Esqueceu a Senha?'
    ],
    security: [
      'Politica de Segurança',
    ],
    cart: [
      'Carrinho',
      'Cursos no Carrinho',
      'CONFIRMAR',
      'Inserir Voucher',
      'APLICADO',
      'APLICAR',
      'O carrinho está vazio. Encontre um curso para você!',
      'ir às compras'
    ],
    coursecart: [
      'Veja mais',
      'Vejas as datas disponíveis aqui!',
      'Adicionar',
      'Datas disponíveis',
      'Horário:',
      'Clique para comprar!',
      'Apenas',
      'vaga(s)',
      'Sem vagas disponíveis!',
      'Sem vagas disponíveis!'
    ],
    dashboard: [
      'Oi',
      'EDITAR',
      'Estudante',
      'LIMITE:',
      'DATA CURSO:',
      'Finalizado',
      'Expirado',
      'Abrir',
      'Comprado',
      'Agendar'
    ],
    editUser: [
      'Editar Usuário',
      'Nome',
      'Endereço',
      'Celular',
      'ATUALIZAR',
      'CANCELAR'
    ],
    createUser: [
      'Cadastro realizado com sucesso!',
      'Fazer Login'
    ],
    congratulations: [
      'Parabéns! Você finalizou o curso Food Safety - HACCP(Level 1).',
      'O certificado foi enviado para o seu email!',
      'Voltar para Área do Aluno'
    ],
    gallery: [
      'NOSSA GALERIA',
      'Nenhuma imagem para ser carregada',
      'Nosso Espaço',
      'Nossas Aulas',
      'Nossa História'
    ]
  }
};

export default PT_DATA;