const vimeo = 'https://player.vimeo.com/video';

// Modulo 1
const videoIntro = `${vimeo}/475114252`;
const videoModulo11 = `${vimeo}/472851755`;
const videoModulo12 = `${vimeo}/475439033`;
const videoModulo13 = `${vimeo}/475440168`;
const videoModulo14 = `${vimeo}/475441435`;
// Modulo 2
const videoModulo21 = `${vimeo}/475446662`;
const videoModulo22 = `${vimeo}/475443878`;
const videoModulo23 = `${vimeo}/472818359`;
// Modulo 3
const videoModulo31 = `${vimeo}/472955868`;
const videoModulo32 = `${vimeo}/472962508`;
const videoModulo33 = `${vimeo}/475450120`;
// Modulo 4
const videoModulo41 = `${vimeo}/475457750`;
const videoModulo42 = `${vimeo}/474997447`;
const videoModulo43 = `${vimeo}/472175806`;
// Modulo 5
const videoModulo51 = `${vimeo}/472976193`;
const videoModulo52 = `${vimeo}/472277371`;
// Modulo 6
const videoModulo61 = `${vimeo}/472982039`;
const videoModulo62 = `${vimeo}/472832717`;

const SIDEMNU = [
  {
    id: 1,
    title: 'Módulo 1',
    items: [{
        id: 1,
        link: '1.0',
        title: 'Informações Importantes',
        video: videoIntro,
        hidden: false,
        watched: false
      }, {
        id: 2,
        link: '1.1',
        title: '1.1 Introdução',
        video: videoModulo11,
        hidden: true,
        watched: false
      },{
        id: 3,
        link: '1.2',
        title: '1.2 Termos, Definições e Vocabulário',
        video: videoModulo12,
        hidden: true,
        watched: false
      }, {
        id: 4,
        link: '1.3',
        title: '1.3 Requerimentos Legais em Segurança Alimentar e Legislação',
        video: videoModulo13,
        hidden: true,
        watched: false
      }, {
        id: 5,
        link: '1.4',
        title: '1.4 Segurança Alimentar: Benefícios e Consequências em caso de Negligência',
        video: videoModulo14,
        hidden: true,
        watched: false
      }, {
        id: 5,
        link: '1.5',
        title: 'Revisão',
        hidden: true,
        watched: false
    }],
    hidden: false,
    watched: false,
    enabled: true
  }, {
    id: 2,
    title: 'Módulo 2',
    items: [{
        id: 1,
        link: '2.1',
        title: '2.1 Higiene Alimentar',
        video: videoModulo21,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '2.2',
        title: '2.2 Higiene Pessoal',
        video: videoModulo22,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '2.3',
        title: '2.3 Limpeza e Higienização',
        video: videoModulo23,
        hidden: true,
        watched: false
      }, {
        id: 4,
        link: '2.4',
        title: 'Revisão',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 3,
    title: 'Módulo 3',
    items: [{
      id: 1,
        link: '3.1',
        title: '3.1 Perigos da Contaminação Alimentar',
        video: videoModulo31,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '3.2',
        title: '3.2 Intoxicação e Infecção Alimentar',
        video: videoModulo32,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '3.3',
        title: '3.3 Crescimento Bacteriano',
        video: videoModulo33,
        hidden: true,
        watched: false
      }, {
        id: 4,
        link: '3.4',
        title: 'Revisão',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 4,
    title: 'Módulo 4',
    items: [{
        id: 1,
        link: '4.1',
        title: '4.1 Transporte, Distribuição e Armazenamento',
        video: videoModulo41,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '4.2',
        title: '4.2 Preparando Alimentos',
        video: videoModulo42,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '4.3',
        title: '4.3 Servindo Alimentos',
        video: videoModulo43,
        hidden: true,
        watched: false
      }, {
        id: 4,
        link: '4.4',
        title: 'Revisão',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 5,
    title: 'Módulo 5',
    items: [{
        id: 1,
        link: '5.1',
        title: '5.1 HACCP',
        video: videoModulo51,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '5.2',
        title: '5.2 Treinamentos e Registros',
        video: videoModulo52,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '5.3',
        title: 'Revisão',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 6,
    title: 'Módulo 6',
    items: [{
        id: 1,
        link: '6.1',
        title: '6.1 Controle de Pragas',
        video: videoModulo61,
        hidden: true,
        watched: false
      }, {
        id: 2,
        link: '6.2',
        title: '6.2 Instalações',
        video: videoModulo62,
        hidden: true,
        watched: false
      }, {
        id: 3,
        link: '6.3',
        title: 'Revisão',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }, {
    id: 7,
    title: 'TESTE FINAL',
    items: [{
        id: 1,
        link: '7.1',
        title: 'Prova',
        hidden: true,
        watched: false
    }],
    hidden: true,
    watched: false,
    enabled: false
  }
];

export default SIDEMNU;