const QUESTIONS = {
  modules: [{
      id: 1,
      questions: [{
        id: 1,
        name: '1.1',
        question: `Read the sentence below and check True or False: “Cross-contamination is defined as the direct or indirect transfer of a biological, chemical agent, among other contaminants, from one place/food to another that can cause that food to be unsafe for human consumption.”`,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '1'
      }, {
        id: 2,
        name: '1.2',
        question: 'The main objective of food legislation is... ',
        options: [{
            value: '1',
            text: 'To guarantee a high level of protection of human life and health and the protection of consumer interests;'
          }, {
            value: '2',
            text: 'To guarantee the standardisation of all businesses with limited flexibility and at the level national; also'
          }, {
            value: '3',
            text: 'To ensure that companies pay the necessary fees to the government for foods;'
          }, {
            value: '4',
            text: 'To guarantee the closing of businesses that do not pay product fees;'
        }],
        answer: '1'
      }, {
        id: 3,
        name: '1.3',
        question: 'Check the correct alternative regarding the benefits of acting following the Food Safety Regulations: ',
        options: [{
            value: '1',
            text: 'Create a space with better working conditions that protect the handler from foods.'
          }, {
            value: '2',
            text: 'Reduce the risk of bacterial contamination.'
          }, {
            value: '3',
            text: 'To improve the image and reputation of the business and pass an inspection with a classification of food hygiene of the highest standard, which can be displayed on your facilities to add value to the company.'
          }, {
            value: '4',
            text: 'All of the above.'
        }],
        answer: '4'
      }, {
        id: 4,
        name: '1.4',
        question: `“Foodborne diseases can have a substantial socio-economic weight, taking into account that victims may become incapacitated for work and, in some cases, it can even be fatal.” This statement is: `,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '1'
      }, {
        id: 5,
        name: '1.5',
        question: `“The food handler is not responsible for following all the food sector legal requirements under its control (Regulation EC No. 178/2002) and it’s not necessary to receive training and supervision for the food safety practices.” 
This statement is: `,
        options: [{
          value: '1',
          text: 'True'
        }, {
          value: '2',
          text: 'False'
        }, {
          value: '3',
          text: ''
        }, {
          value: '4', 
          text: ''
        }],
        answer: '2'
      }]
    }, {
      id: 2,
      questions: [{
        id: 1,
        name: '2.1',
        question: `“Food Hygiene is fundamental to develop the conditions and measures necessary to guarantee the safety and suitability of food throughout the production chain, from the producer to consumer.” This statement is:`,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '1'
      }, {
        id: 2,
        name: '2.2',
        question: 'Which national authority is responsible for coordinating the application of the legislation on food safety in Ireland? ',
        options: [{
            value: '1',
            text: 'NCFI (National College of Food of Ireland).'
          }, {
            value: '2',
            text: 'FSAI (Food Safety Authority of Ireland).'
          }, {
            value: '3',
            text: 'AFI (Authority of Food of Ireland).'
          }, {
            value: '4',
            text: 'None of the above.'
        }],
        answer: '2'
      }, {
        id: 3,
        name: '2.3',
        question: 'Identify the consequences of the lack of food hygiene:',
        options: [{
            value: '1',
            text: 'The risk of causing severe diseases, which can even lead to death.'
          }, {
            value: '2',
            text: 'Destroy the image and reputation of the business.'
          }, {
            value: '3',
            text: 'If a company breaks the rules, depending on the severity, it may be closed by the authorities, in addition to having the case published in the media.'
          }, {
            value: '4',
            text: 'All of the above.'
        }],
        answer: '4'
      }, {
        id: 4,
        name: '2.4',
        question: 'What is the main reason why the food worker should adopt good practices of Personal Hygiene?',
        options: [{
            value: '1',
            text: 'Maintain personal hygiene is part of the HACCP system and is one of the prerequisites to prevent food contamination.'
          }, {
            value: '2',
            text: 'If the employee does not maintain good personal hygiene, it may cause conflicts with the business owner and lose his job.'
          }, {
            value: '3',
            text: 'It is necessary to have a clean appearance if the establishment goes through any audit.'
          }, {
            value: '4',
            text: 'Receive criticism from customers, managers and supervisors.'
        }],
        answer: '1'
      }, {
        id: 5,
        name: '2.5',
        question: 'The food handler needs to make sure to wash his hands after:',
        options: [{
          value: '1',
          text: 'Dealing with raw meat and allergenic foods.'
        }, {
          value: '2',
          text: 'Coughing, sneezing, scratching ear, nose, eyes, touching hair and smoking.'
        }, {
          value: '3',
          text: 'Handling residues and chemicals.'
        }, {
          value: '4',
          text: 'All of the above.'
        }],
        answer: '4'
      }]
    }, {
      id: 3,
      questions: [{
        id: 1,
        name: '3.1',
        question: `Read the following sentence and check True or False: “Food hazards is a biological agent that is almost impossible to eliminate.”`,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '2'
      }, {
        id: 2,
        name: '3.2',
        question: `Complete the following sentence with the correct alternative: “Physical hazards occur when foreign objects, such as ______contaminate the food.”`,
        options: [{
            value: '1',
            text: 'Bacteria, viruses and parasites.'
          }, {
            value: '2',
            text: 'Glass, metals, bones, stones, wood, plastics.'
          }, {
            value: '3',
            text: 'Pesticides, antibiotics, cleaning product residue.'
          }, {
            value: '4',
            text: 'None of the above.'
        }],
        answer: '2'
      }, {
        id: 3,
        name: '3.3',
        question: 'Check the incorrect alternative:',
        options: [{
            value: '1',
            text: 'Biological hazards of food origin include microorganisms such as bacteria, viruses and parasites.'
          }, {
            value: '2',
            text: 'Anaphylactic shock is a severe allergic reaction that occurs within seconds or minutes after being in contact with a substance to which you are allergic.'
          }, {
            value: '3',
            text: 'Pathogenic bacteria are responsible for the vast majority of outbreaks and cases of foodborne diseases.'
          }, {
            value: '4',
            text: `Raw and animal origin food are at low risk of contamination as they have nutrients and water that don't allow microorganism multiplication.`
        }],
        answer: '4'
      }, {
        id: 4,
        name: '3.4',
        question: `Check if the sentence below is True or False: “Food poisoning happens through food/drinks contaminated with bacteria, parasites, viruses, toxins or chemical agents, and can happen between 1 to 72 hours after eating contaminated food.”`,
        options: [{
            value: '1',
            text: 'True.'
          }, {
            value: '2',
            text: 'False.'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '1'
      }, {
        id: 5,
        name: '3.5',
        question: 'Which people are generally not considered to be part of vulnerable groups?',
        options: [{
            value: '1',
            text: 'Older adults.'
          }, {
            value: '2',
            text: 'Adolescents.'
          }, {
            value: '3',
            text: 'People receiving medical treatments, such as chemotherapy.'
          }, {
            value: '4',
            text: 'Pregnant women.'
        }],
        answer: '2'
      }]
    }, {
      id: 4,
      questions: [{
        id: 1,
        name: '4.1',
        question: `“The food handler must make sure that the first goods to arrive are the first to be sold. Therefore, new products should be stored behind the already stored, according to the system known as FIFO: First In, First Out.” This statement is:`,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '1'
      }, {
        id: 2,
        name: '4.2',
        question: 'Check the correct alternative: ',
        options: [{
            value: '1',
            text: 'The food handler must control and record the temperature of the fridges/freezers at least twice a week.'
          }, {
            value: '2',
            text: 'The food handler must control and record the food fridges/freezers’ temperature at least twice a day.'
          }, {
            value: '3',
            text: 'The food handler must control and record the temperature of the fridges/freezers at least twice every two months.'
          }, {
            value: '4',
            text: 'The food handler must control and record the temperature of the fridges/freezers at least twice every hour.'
        }],
        answer: '2'
      }, {
        id: 3,
        name: '4.3',
        question: 'Check the correct alternative for one of the preventive measures when preparing food: ',
        options: [{
            value: '1',
            text: 'Use a cloth moistened with disinfectant on the food board to clean it between each operation.'
          }, {
            value: '2',
            text: 'Use the same knife for different types of food, but wipe with a cloth with water hot between each operation.'
          }, {
            value: '3',
            text: 'Cut vegetables and raw fish together and at the same time, as it helps to prevent cross-contamination.'
          }, {
            value: '4',
            text: 'Use an organised system of colours between the utensils to assist in food segregation. '
        }],
        answer: '4'
      }, {
        id: 4,
        name: '4.4',
        question: 'Fridges and refrigeration thermostat displays should be adjusted to ensure that the food is at temperatures between:',
        options: [{
            value: '1',
            text: '7°C and 20°C'
          }, {
            value: '2',
            text: '0°C and 5°C'
          }, {
            value: '3',
            text: '0°C and 15°C '
          }, {
            value: '4',
            text: '10°C and 15°C'
        }],
        answer: '2'
      }, {
        id: 5,
        name: '4.5',
        question: '“Temperatures between 0°C - 5°C are dangerous, as it is between these temperatures that bacteria can multiply more quickly.” This statement is: ',
        options: [{
          value: '1',
          text: 'True'
        }, {
          value: '2',
          text: 'False'
        }, {
          value: '3',
          text: ''
        }, {
          value: '4',
          text: ''
        }],
        answer: '2'
      }]
    }, {
      id: 5,
      questions: [{
        id: 1,
        name: '5.1',
        question: 'Check the incorrect alternative:',
        options: [{
            value: '1',
            text: 'HACCP is a system that identifies, evaluates, and controls hazards that are significant for Food Safety.'
          }, {
            value: '2',
            text: 'HACCP é a abreviação em inglês de Hazard Analysis and Critical Control Point.'
          }, {
            value: '3',
            text: 'The HACCP system is based on 24 principles.'
          }, {
            value: '4',
            text: 'All companies in the food sector, engaged in any phase of production, transformation, storage, distribution of foodstuffs, have to develop the HACCP system.'
        }],
        answer: '3'
      }, {
        id: 2,
        name: '5.2',
        question: 'Which alternative corresponds to the origin and creation of the HACCP system: ',
        options: [{
            value: '1',
            text: 'The HACCP system was created and developed in the 1960s by the American company Pillsbury, in partnership with the American Armed Forces, to respond to a request from the FBI. '
          }, {
            value: '2',
            text: 'The HACCP system was created and developed in the 1960s by the company American Pillsbury, in partnership with the American Armed Forces, to fulfil a request from NASA. '
          }, {
            value: '3',
            text: 'The HACCP system was created and developed in the 1960s by the American company Pillsbury, in partnership with the American Armed Forces, to fulfil a request from the CIA.'
          }, {
            value: '4',
            text: 'The HACCP system was created and developed in the 1960s by the American company Pillsbury, in partnership with the American Armed Forces, to fulfil a request exclusive of the President. '
        }],
        answer: '2'
      }, {
        id: 3,
        name: '5.3',
        question: `Tick True or False: “HACCP is an approach created with the main objective of reducing the expenses of establishments and delivering a good image of food safety for the consumer, therefore, stimulate the local and global economy.”`,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '2'
      }, {
        id: 4,
        name: '5.4',
        question: 'Check the advantages of applying the HACCP system:',
        options: [{
            value: '1',
            text: 'Increasing food safety and public health.'
          }, {
            value: '2',
            text: 'Reduction of complaints and refunds.'
          }, {
            value: '3',
            text: 'Waste reduction. '
          }, {
            value: '4',
            text: 'All of the above are correct.'
        }],
        answer: '4'
      }, {
        id: 5,
        name: '5.5',
        question: `Check True or False: “The high sanitary standard of food can be acquired through programs of training of food workers, with specific training, this being one of the pre-requirements to avoid food contamination.”`,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '1'
      }]
    }, {
      id: 6,
      questions: [{
        id: 1,
        name: '6.1',
        question: 'Check the correct alternative regarding the type of pests:',
        options: [{
            value: '1',
            text: 'Manager, supervisor, business owner and customers. '
          }, {
            value: '2',
            text: 'Mosquitoes, flies, rats, cockroaches and pigeons.'
          }, {
            value: '3',
            text: 'Chemicals in food and pesticides. '
          }, {
            value: '4',
            text: 'All alternatives are correct. '
        }],
        answer: '2'
      }, {
        id: 2,
        name: '6.2',
        question: `“A destructive measure is much easier to apply than a preventive pest measure.” This statement is: `,
        options: [{
            value: '1',
            text: 'True'
          }, {
            value: '2',
            text: 'False'
          }, {
            value: '3',
            text: ''
          }, {
            value: '4',
            text: ''
        }],
        answer: '2'
      }, {
        id: 3,
        name: '6.3',
        question: 'Tick the incorrect alternative in terms of preventive pest control measures: ',
        options: [{
            value: '1',
            text: 'Keep windows, doors, drains, drains closed/sealed. '
          }, {
            value: '2',
            text: 'Maintain a constant stock rotation and clean environment.'
          }, {
            value: '3',
            text: 'Identify the pest (s) that caused the infestation. '
          }, {
            value: '4',
            text: 'Store food on shelves away from the floor and not touching the walls. '
        }],
        answer: '3'
      }, {
        id: 4,
        name: '6.4',
        question: 'Check the incorrect alternative regarding indications of pests in the establishment:',
        options: [{
            value: '1',
            text: 'Damaged packaging. '
          }, {
            value: '2',
            text: 'The door to the freezer and the kitchen open.'
          }, {
            value: '3',
            text: 'Animal faeces and urine.'
          }, {
            value: '4',
            text: 'Hair of animals.'
        }],
        answer: '2'
      }, {
        id: 5,
        name: '6.5',
        question: `Check the correct alternative: In order to comply with food safety law, facilities must be designed in a way that:`,
        options: [{
            value: '1',
            text: 'Facilitate cleaning.'
          }, {
            value: '2',
            text: 'Reduce the risk of contamination.'
          }, {
            value: '3',
            text: 'Facilitate maintenance. '
          }, {
            value: '4',
            text: 'All of the above.'
        }],
        answer: '4'
      }]
    }
  ]
};

export default QUESTIONS;