import boutique1 from '../../assets/images/gallery/boutique/boutique1.jpeg';
import boutique2 from '../../assets/images/gallery/boutique/boutique2.jpeg';
import boutique3 from '../../assets/images/gallery/boutique/boutique3.jpeg';
import boutique4 from '../../assets/images/gallery/boutique/boutique4.jpeg';
import boutique5 from '../../assets/images/gallery/boutique/boutique5.jpeg';

import class1 from '../../assets/images/gallery/classes/class1.jpeg';
import class2 from '../../assets/images/gallery/classes/class2.jpeg';
import class3 from '../../assets/images/gallery/classes/class3.jpeg';
import class4 from '../../assets/images/gallery/classes/class4.jpeg';
import class5 from '../../assets/images/gallery/classes/class5.jpeg';
import class6 from '../../assets/images/gallery/classes/class6.jpeg';
import class7 from '../../assets/images/gallery/classes/class7.jpeg';
import class8 from '../../assets/images/gallery/classes/class8.jpeg';

import story1 from '../../assets/images/gallery/our_history/story1.jpeg';
import story2 from '../../assets/images/gallery/our_history/story2.jpeg';
import story3 from '../../assets/images/gallery/our_history/story3.jpeg';
import story4 from '../../assets/images/gallery/our_history/story4.jpeg';
import story5 from '../../assets/images/gallery/our_history/story5.jpeg';
import story6 from '../../assets/images/gallery/our_history/story6.jpeg';
import story7 from '../../assets/images/gallery/our_history/story7.jpeg';
import story8 from '../../assets/images/gallery/our_history/story8.jpeg';
import story9 from '../../assets/images/gallery/our_history/story9.jpeg';
import story10 from '../../assets/images/gallery/our_history/story10.jpeg';


const GALLERY_DATA = [
    {
        img: boutique1,
        type: 'boutique',
        title: '',
    },
    {
        img: boutique2,
        type: 'boutique',
        title: '',
    },
    {
        img: boutique3,
        type: 'boutique',
        title: '',
    },
    {
        img: boutique4,
        type: 'boutique',
        title: '',
    },
    {
        img: boutique5,
        type: 'boutique',
        title: '',
    },
    {
        img: class1,
        type: 'class',
        title: '',
    },
    {
        img: class2,
        type: 'class',
        title: '',
    },
    {
        img: class3,
        type: 'class',
        title: '',
    },
    {
        img: class4,
        type: 'class',
        title: '',
    },
    {
        img: class5,
        type: 'class',
        title: '',
    },
    {
        img: class6,
        type: 'class',
        title: '',
    },
    {
        img: class7,
        type: 'class',
        title: '',
    },
    {
        img: class8,
        type: 'class',
        title: '',
    },
    {
        img: story1,
        type: 'story',
        title: 'Latte Art training at Munch (Dublin)',
    },
    {
        img: story2,
        type: 'story',
        title: 'Lovely Staff training at Lanesbrew (Longford)',
    },
    {
        img: story3,
        type: 'story',
        title: 'Latte Art Training at Melt Gelato (Navan)',
    },
    {
        img: story4,
        type: 'story',
        title: 'Staff Training at Liz’s Bakery (Tipperary)',
    },
    {
        img: story5,
        type: 'story',
        title: 'HACCP - Food Safety Training at The Spencer Hotel (Dublin)',
    },
    {
        img: story6,
        type: 'story',
        title: 'Barista Workshop at SEDA College (Dublin)',
    },
    {
        img: story7,
        type: 'story',
        title: 'Barista Workshop at ICD Business School (Dublin)',
    },
    {
        img: story8,
        type: 'story',
        title: 'Barista Workshop at Atlas (Dublin)',
    },
    {
        img: story9,
        type: 'story',
        title: 'Barista Worshop at Egali (Dublin)',
    },
    {
        img: story10,
        type: 'story',
        title: 'Barista Workshop at SEDA College (Dublin)',
    },
]

export default GALLERY_DATA;