export const addItemToCart = ( cartItems, cartItemToAdd ) => {
    const existingCartItem = cartItems.find(
        cartItem => cartItem._id === cartItemToAdd._id
    );

    if ( existingCartItem ) {
        return cartItems.map( cartItem => 
            cartItem._id === cartItemToAdd._id ? 
                { ...cartItem, quantity: cartItem.quantity } 
                : cartItem
        );
    }

    return [...cartItems, {...cartItemToAdd, quantity: 1}];
}

export const removeItemCart = (cartItems, cartItemToRemove) => {
    // removing barista course from combo
    if (cartItemToRemove.is_combo) {
        return cartItems
            .filter(cartItem => cartItem._id !== cartItemToRemove._id)
            .filter(cartItem => !cartItem.is_barista)
            .filter(cartItem => !cartItem.type === 'online');
    }

    return cartItems.filter(cartItem => cartItem._id !== cartItemToRemove._id);
}