import React from "react";
import { connect } from "react-redux";

import Alert from "@material-ui/lab/Alert";
import { Grid } from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link";

import { setCurrentUser } from "../../redux/user/user.actions";

import api from "../../services/api";

import "./Success.scss";

class Success extends React.Component {
  async componentDidMount() {
    const cart = JSON.parse(localStorage.getItem("userCart"));
    const userId = localStorage.getItem("userId");
    const voucher = localStorage.getItem("voucher")
      ? localStorage.getItem("voucher")
      : "";

    if (userId && cart) {
      let response = await api.get(`/user/${userId}`);

      const name = response.data.name;
      const email = response.data.email;

      setCurrentUser({
        id: response.data.id,
        name: name,
        email: email,
        type: response.data.type,
      });

      Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);

        return date;
      };
      const date = new Date();

      cart.forEach(async (item) => {
        response = await api.post("/user-courses", {
          user_id: userId,
          course_id: item._id,
          deadline: date.addDays(item.duration),
          type: item.type,
          voucher: voucher,
          dateCourse: item.dateCourse,
        });
      });

      const resp = await api.get("/vouchers");
      const vouchers = resp.data;

      vouchers.forEach(async (vouch) => {
        if (vouch?.name === voucher) {
          await api.put("/voucher", {
            id: vouch?._id,
          });
        }
      });

      if (!response.error) {
        localStorage.setItem("userCart", "");
        localStorage.setItem("voucher", "");

        await api.post("/send-purchased", {
          name: name,
          email: email,
        });
      }
    }
  }

  render() {
    return (
      <div className="container success">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Alert severity="success">Thank you for your purchase!</Alert>
          </Grid>
          <Grid item xs={12}>
            <Link className="btn-purple" to="/dashboard">
              Go to your dashboard
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  };
};

export default connect(null, mapDispatchToProps)(Success);
