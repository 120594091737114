import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { registerLicense } from '@syncfusion/ej2-base';

import { Provider } from 'react-redux';
import store from './redux/store';

import './index.css';
import App from './App';

registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkxhW39Yc3ZXTmlVWUQ=');

ReactDOM.render(
  <Provider store={store}>
    <ParallaxProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ParallaxProvider>
  </Provider>,  
  document.getElementById('root')
);