import React, { useState, useEffect, useRef } from "react";

import Player from "@vimeo/player";

import SIDEMENU from '../../../data/menu/sidemenu.data';

import CourseForm from '../CoursePTForm/CoursePTForm';

import api from "../../../services/api";

import './CoursePTContent.scss';

function CourseContent() {
  const [menu, setMenu] = useState(SIDEMENU);

  function VimeoPlayer({ onEnded = () => {}, ...attrs }) {
    const ref = useRef(null);

    useEffect(() => {
      const player = new Player(ref.current);

      player.on("ended", onEnded);

      return () => {
        player.off("ended", onEnded);
      };
    }, [onEnded]);

    return <iframe ref={ref} {...attrs} />;
  }

  async function handleEnded(level, module) {
    const userId = localStorage.getItem('userId');

    await api.put(`/user-courses`, {
      user_id: userId,
      course_id: '5f83853c539a937ed63fbf2c',
      level,
      module
    }).then(async response => {
      if (response.status === 200) {
        window.location.reload();
      }
    });
  }

  return (
    <div className="container course-content">
      {menu.map((item, index) => (
        <div key={index}>
          {item?.items.map((subitem, index) => (
            <section
              key={index}
              id={subitem.link}
              className={`${subitem?.hidden ? "hide" : ""}`}
            >
              {!subitem?.hidden ? (
                subitem?.video ? (

                  subitem.id === 8 ? (
                    <div>Test</div>
                  ) : (
                    <div>
                      <VimeoPlayer
                        title={`${subitem.link}`}
                        className="video"
                        frameBorder="0"
                        src={subitem.video}
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        onEnded={async () => {
                          handleEnded(item.id, subitem.id + 1);
                        }}
                      />
                      {
                        item.id === 2 && subitem.id === 1 ? (
                          <div className="links">
                            <h4>Links: </h4>
                            <a href="https://www.fsai.ie" target="_blank">www.fsai.ie</a>
                          </div>
                        ) : ''
                      }
                    </div>
                  )
                ) : (
                  <CourseForm moduleId={item?.id} />
                )
              ) : (
                ""
              )}
            </section>
          ))}
        </div>
      ))}
    </div>
  );
}

export default CourseContent;