import React, { useState } from "react"
import { connect } from "react-redux"

import { useHistory, Link } from "react-router-dom"

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"

import { CalendarComponent } from "@syncfusion/ej2-react-calendars"

import { addItem } from "../../redux/cart/cart.actions"

import EN_DATA from "../../data/language/english.data"
import PT_DATA from "../../data/language/portuguese.data"

import api from "../../services/api"

import "./CourseCart.scss"

function CourseCart({ course, courses, addItem, language }) {
  const text =
    language === "EN"
      ? EN_DATA.sections.coursecart
      : PT_DATA.sections.coursecart
  const description1 =
    language === "EN" ? course?.description1 : course?.description1_pt
  const description2 =
    language === "EN" ? course?.description2 : course?.description2_pt
  // get barista course by id
  const courseBarista = courses
    ? courses.filter((course) => course.is_barista)[0]
    : ""

  const [hide, setHide] = useState(true)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [spotLeft, setSpotLeft] = useState(() => {
    return courseBarista?.calendar[0]?.limit || 0
  })
  const [currentDate, setCurrentDate] = useState(() => {
    if (courseBarista) {
      return courseBarista?.calendar[0]
    }
    return []
  })
  const [events, setEvents] = useState(() => {
    if (courseBarista) {
      const events = []

      courseBarista.calendar.forEach((item) => {
        events.push(item.day)
      })

      return events
    }

    return []
  })
  const history = useHistory()

  const handleClose = () => {
    setShowCalendar(false)
  }

  const handleMessageClose = () => {
    setShowMessage(false)
  }

  const onChangeDate = async (event) => {
    if (courseBarista) {
      const dateSelected = courseBarista.calendar.find((el) => {
        return formatDate(el.day) === formatDate(event.value)
      })

      await updateSpotLeft(dateSelected)
      setCurrentDate(dateSelected)
    }
  }

  const dateExist = async (dateSelected) => {
    const response = await api.get(`/courses-barista-date/`, {
      params: {
        dateSelected: dateSelected.day,
      },
    })

    return response?.data.dateExists
  }

  const updateSpotLeft = async (dateSelected) => {
    const response = await api.get(`/user-courses-dates/${courseBarista._id}`)
    const dates = response?.data

    let spotsLeft = 0
    if (dates.length > 0) {
      let qttyDate = 0
      dates.forEach((el) => {
        if (
          formatDate(el, "database") ===
          formatDate(dateSelected.day, "database")
        ) {
          qttyDate++
        }
      })

      spotsLeft = dateSelected?.limit - qttyDate
    } else {
      const exist = await dateExist(dateSelected)
      if (exist) {
        spotsLeft = dateSelected?.limit
      }
    }
    setSpotLeft(spotsLeft)

    return spotsLeft
  }

  const disabledDate = (event) => {
    let isInclude = false
    events.forEach((item) => {
      if (formatDate(event.date) === formatDate(item)) {
        isInclude = true
      }
    })

    if (!isInclude) {
      event.isDisabled = true
    }
  }

  const formatDate = (date, label = "") => {
    const formDate = new Date(date)
    let day = formDate.getDate()
    let month = formDate.getMonth() + 1
    const year = formDate.getFullYear()

    if (day.toString().length === 1) {
      day = "0" + day
    }

    if (month.toString().length === 1) {
      month = "0" + month
    }

    if (label === "label") {
      return day + "/" + month
    }

    if (label === "database") {
      return year + "-" + month + "-" + day
    }

    return day + "/" + month + "/" + year
  }

  const setPriceZero = (course) => {
    const item = JSON.parse(JSON.stringify(course))
    item.price = 0

    return item
  }

  const addCourseBarista = () => {
    const hours = currentDate.hour.split(":")
    const dateCourse = new Date(formatDate(currentDate.day, "database"))
    dateCourse.setHours(hours[0], hours[1])

    if (course?._id === courseBarista._id) {
      addItem({ ...course, ...{ dateSelected: currentDate, dateCourse } })
    } else {
      addItem({
        ...setPriceZero(courseBarista),
        ...{ dateSelected: currentDate, dateCourse },
      })
    }

    handleClose()
  }

  const addFoodSafetyOnline = () => {
    const courseId =
      language === "EN"
        ? "60b35888a6e53a00171c7efb"
        : "5f83853c539a937ed63fbf2c"
    const foodSafetyCourse = courses
      ? courses.find((course) => course._id === courseId)
      : ""

    addItem({ ...setPriceZero(foodSafetyCourse), ...{ dateCourse: "" } })
  }

  const isCombo = (courseId) => {
    const comboCourses = new Set([
      "5f8b0e213fb21fbf08d0bc11", // COMBO START
      "61b4fb459f146a00182881f0", // COMBO BARISTA + PRIVATE SESSION + FOOD SAFETY
      "5f8b0eac3fb21fbf08d0bc12", // COMBO HEAD START
    ])

    return comboCourses.has(courseId)
  }

  const handleClick = async (course, courseBarista) => {
    const newSpotLeft = await updateSpotLeft(currentDate)

    if (newSpotLeft > 0) {
      // COMBOS
      if (isCombo(course._id)) {
        addItem({ ...course, ...{ dateCourse: "" } })
        addCourseBarista()
        addFoodSafetyOnline()
      } else {
        // BARISTA COURSE
        if (course._id === courseBarista._id) {
          addCourseBarista()
        } else {
          addItem({ ...course, ...{ dateCourse: "" } })
          addCourseBarista()
        }
      }

      history.push("/cart")
    }
  }

  return (
    <Card className="card">
      <CardContent>
        <h3 className="card-title txt-border">{course?.name}</h3>
        {course?._id === courseBarista._id &&
        courseBarista?.calendar.length > 0 ? (
          <Link
            className="card-days"
            to=""
            onClick={() => setShowCalendar(true)}
          >
            <h4>
              <u>{text[1]}</u>
            </h4>
            <CalendarTodayIcon />
          </Link>
        ) : (
          ""
        )}

        {showCalendar ? (
          <Modal
            aria-labelledby="modal-title-calendar"
            aria-describedby="modal-description-calendar"
            className="item-modal"
            open={showCalendar}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showCalendar}>
              <div className="paper-calendar">
                <div className="modal-label">
                  <span></span>
                  <label>{text[3]}</label>
                </div>
                <div id="modal-description-calendar">
                  <CalendarComponent
                    id="calendar"
                    change={onChangeDate}
                    renderDayCell={disabledDate}
                    values={events}
                  />
                </div>
                <div className="paper-label">
                  <div className="paper-date">
                    <label>
                      <b>{formatDate(currentDate.day, "label")}</b>
                    </label>
                  </div>
                  <div className="paper-description">
                    <label>
                      <b>Barista Course</b>
                    </label>
                    <div className="paper-buy">
                      <div>
                        {text[4]} <b>{currentDate.hour}</b>
                      </div>

                      {spotLeft > 0 ? (
                        <div>
                          <Link
                            className="paper-click"
                            onClick={() => {
                              handleClick(course, courseBarista)
                            }}
                          >
                            <b>
                              <u>{text[5]}</u>
                            </b>
                          </Link>
                          <div className="paper-left">
                            {text[6]} {spotLeft} {text[7]}
                          </div>
                        </div>
                      ) : (
                        <div className="paper-left">{text[8]}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        ) : (
          ""
        )}

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="item-modal"
          open={showMessage}
          onClose={handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showMessage}>
            <div className="paper">
              <h2 id="modal-title">{text[9]}</h2>
            </div>
          </Fade>
        </Modal>

        <div className="card-description">
          <div>
            <p>{description1}</p>

            <div className={`card-link ${hide && description2 ? "" : "hide"}`}>
              <a onClick={() => setHide(!hide)}>{text[0]}</a>
            </div>
          </div>

          <div className={`${hide ? "hide" : ""}`}>
            <p>{description2}</p>
            <div className="card-link">
              <a onClick={() => setHide(!hide)}>
                <ArrowBackIcon />
              </a>
            </div>
          </div>
        </div>

        {course?.price !== 0 ? (
          <div className="card-price">€{course.price}</div>
        ) : (
          ""
        )}
      </CardContent>

      <CardActions className="btn-container">
        {course?.price !== 0 ? (
          <Button
            className="btn-orange"
            onClick={() => {
              if (course?.is_barista || course?.is_combo) {
                if (courseBarista?.calendar.length > 0) {
                  setShowCalendar(true)
                } else {
                  setShowMessage(true)
                }
              } else {
                addItem({ ...course, ...{ dateCourse: "" } })
                history.push("/cart")
              }
            }}
          >
            {text[2]}
          </Button>
        ) : (
          ""
        )}
      </CardActions>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  cartItems: state.cartItems,
})

const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (cartItems) => dispatch(addItem(cartItems)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCart)
