import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import axios from 'axios';

import { selectLanguage } from '../../redux/language/language.selectors';

import EN_DATA from '../../data/language/english.data';
import PT_DATA from '../../data/language/portuguese.data';

import CircularProgress from "@material-ui/core/CircularProgress";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

import './Gallery.scss';

import GALLERY_DATA from '../../data/gallery/gallery.data';

class Gallery extends React.Component {
    constructor() {
      super();

      this.state = {
        galleyImg: [],
        loading: true
      }
    };

  async componentDidMount() {
    // const tokenMe = 'IGQVJXUGJQRGlxRHhyZAEJpcEtxVTRCTU1BLVdSSXhEV2JEWWxPd3FjaEpfU1lrczdibHA5UWFValFweGpBQXZA5OWNQLTlhUkV3cDhaX2p1RmFLcTZAra19fdWlYVk9xQVZAwN2xpaEkwWnBxM2dwREFuUwZDZD';
    // const token ="IGQVJWWloyWGxLWWJsalllaXhDaWJIWmllSzZAEREZAxbFFLTlBFRkdTRTdyQ1ZAJTnljNDJxN1FZAYzF6LWJMUmo1clhqelhOWTZAyRzB6WDJKVEFFUkNUM1BGYnRqMnpVdUdmRGl2OGpHYThxSzFtNHBodgZDZD";
    // const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url&access_token=${tokenMe}`;

    // await axios.get(url).then((response) => {
    //   const feed = response.data.data;
    //   const feedImg = [];

    //   if ( feed.length > 0 ) {
    //     for (const media of feed) {
    //       if (media.media_type === 'IMAGE' || media.media_type === 'CAROUSEL_ALBUM') {
    //         feedImg.push(media.media_url);
    //       }
    //     }
    //   }
      
    //   this.setState({
    //     images: feedImg,
    //     loading: false
    //   });
    // }).catch(error => {
    //   console.log(error);
    // });

    this.setState({
      galleyImg: GALLERY_DATA,
      loading: false
    });
  }

  render() {
    const { language } = this.props;
    const { galleyImg, loading } = this.state;
    const text = language === 'EN' ? EN_DATA.sections.gallery : PT_DATA.sections.gallery;

    return (
      <div id="gallery" className="container txt-center gallery">
        <h1>{text[0]}</h1>
        {
          galleyImg.length > 0
          ? ""
        : <div>{text[1]}</div>
        }
        <div className="gallery-carousel">
          {loading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <Carousel className="carousel">
              {
                galleyImg.length > 0
                ? galleyImg.map((item, index) => (
                    <div key={index}>
                      <h3 className="carousel-title">
                        {
                         item.type === 'boutique' ? text[2] : ''
                        }
                        {
                          item.type === 'class' ? text[3] : ''
                        }
                        {
                          item.type === 'story' ? text[4] : ''
                        }
                      </h3>
                      <h3>{ item.title }</h3>
                      <img src={item.img} alt="" />
                    </div>
                  ))
                : ""
              }
            </Carousel>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: selectLanguage
});

export default connect(mapStateToProps, null)(Gallery);