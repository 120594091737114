import React from 'react';

import { Grid, TextField, Button, Select, MenuItem, InputLabel } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';

import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import api from '../../services/api';

import './EditCourse.scss';

class EditCourse extends React.Component {
  constructor() {
    super();

    this.state = {
      id: '',
      name: '',
      description1: '',
      description2: '',
      description1_pt: '',
      description2_pt: '',
      type: '',
      price: 0,
      duration: 0,
      active: false,
      newCalendar: [],
      isCalendar: false,
      newDay: new Date(),
      newHour: '08:00',
      limit: 0
    };
  }
  
  componentDidMount() {
    const id = this.props.match.params.id;

    api.get(`/courses/${id}`)
      .then(response => {
        const course = response.data.course[0];
        this.setState({
          id: id,
          name: course?.name,
          description1: course?.description1,
          description2: course?.description2,
          description1_pt: course?.description1_pt,
          description2_pt: course?.description2_pt,
          type: course?.type,
          price: course?.price,
          duration: course?.duration,
          newCalendar: course?.calendar ? course?.calendar : [],
          active: course?.active
        });
    });
  }

  handleCalendar() {
    const { newCalendar, newDay, newHour, limit } = this.state;

    const calendar = newCalendar;
    calendar.push({ 'day': newDay, 'hour': newHour, 'limit': limit });

    this.setState({
      newCalendar: calendar,
      isCalendar: false
    });
  }

  handleRemoveCalendar( index ) {
    const { newCalendar } = this.state;

    const calendar = newCalendar;
    calendar.splice(index, 1);

    this.setState({
      newCalendar: calendar,
      isCalendar: false
    });
  }

  async handleSubmit() {
    const { 
      id,
      name, 
      description1, 
      description2, 
      description1_pt, 
      description2_pt,
      type,
      price,
      duration,
      newCalendar,
      active
    } = this.state;

    try {
        const response = await api.put(`/courses/${id}`, { 
          id,
          name,
          description1,
          description2,
          description1_pt,
          description2_pt,
          type,
          price,
          duration,
          calendar: newCalendar,
          active
        });

        this.props.history.push('/dashboard');
        window.location.reload(false);
    } catch (error) {
        alert('Edit course error!!!');
    }
  }

  formatDate(date) {
    const formDate = new Date(date);
    const day = formDate.getDate();
    const year = formDate.getFullYear();
    const month = formDate.getMonth() + 1;

    return day + '/' + month + '/' + year;
  }

  render() {
    const { 
      id,
      name, 
      description1, 
      description2, 
      description1_pt, 
      description2_pt,
      type,
      price,
      duration,
      active,
      newCalendar,
      isCalendar,
      newDay,
      newHour,
      limit
    } = this.state;

    return (
      <div className="container edit">
        <h1>Edit Course</h1>

        <form
          onSubmit={(e) => { 
            e.preventDefault();
            this.handleSubmit();
          }}
          className="form-container" 
          noValidate 
          autoComplete="off"
        >

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <h4>English</h4>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="form-input" 
                  required 
                  id="name" 
                  label='Name'
                  type="text"
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="form-input" 
                  required
                  id="description1" 
                  label='Description 1'
                  multiline
                  rows={5}
                  value={description1}
                  onChange={e => this.setState({ description1: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="form-input" 
                  id="description2" 
                  label='Description 2'
                  multiline
                  rows={5}
                  value={description2}
                  onChange={e => this.setState({ description2: e.target.value })}
                />
              </Grid>
              {
                type !== 'online' && id === '5f8b0c293fb21fbf08d0bc0f' ? (
                  <div>
                    <Grid item xs={12}>
                      <h4>Calendar</h4>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className="edit-button"
                        onClick={() => this.setState({ isCalendar: true })}
                      >
                        <AddIcon /> Add Date
                      </Button>
                    </Grid>
                    {
                      isCalendar ? (
                        <div className="calendar-new">
                            <Grid item xs={4}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disablePast
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Date"
                                    format="dd/MM/yyyy"
                                    value={newDay}
                                    onChange = {
                                      date => this.setState({
                                        newDay: date
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    TextFieldComponent={this.TextFieldComponent}
                                  />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid className="form-select" item xs={2}>
                              <InputLabel>Hour</InputLabel>
                              <Select
                                className="form-input"
                                required
                                labelId="select-label"
                                value={newHour}
                                onChange={e => this.setState({ newHour: e.target.value })}
                              >
                                <MenuItem value={"08:00"}>08:00</MenuItem>
                                <MenuItem value={"08:30"}>08:30</MenuItem>
                                <MenuItem value={"09:00"}>09:00</MenuItem>
                                <MenuItem value={"09:30"}>09:30</MenuItem>
                                <MenuItem value={"10:00"}>10:00</MenuItem>
                                <MenuItem value={"10:30"}>10:30</MenuItem>
                                <MenuItem value={"11:00"}>11:00</MenuItem>
                                <MenuItem value={"11:30"}>11:30</MenuItem>
                                <MenuItem value={"12:00"}>12:00</MenuItem>
                                <MenuItem value={"12:30"}>12:30</MenuItem>
                                <MenuItem value={"13:00"}>13:00</MenuItem>
                                <MenuItem value={"13:30"}>13:30</MenuItem>
                                <MenuItem value={"14:00"}>14:00</MenuItem>
                                <MenuItem value={"14:30"}>14:30</MenuItem>
                                <MenuItem value={"15:00"}>15:00</MenuItem>
                                <MenuItem value={"15:30"}>15:30</MenuItem>
                                <MenuItem value={"16:00"}>16:00</MenuItem>
                                <MenuItem value={"16:30"}>16:30</MenuItem>
                                <MenuItem value={"17:00"}>17:00</MenuItem>
                                <MenuItem value={"17:30"}>17:30</MenuItem>
                                <MenuItem value={"18:00"}>18:00</MenuItem>
                                <MenuItem value={"18:30"}>18:30</MenuItem>
                                <MenuItem value={"19:00"}>19:00</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                className="form-input"
                                id="limit"
                                label='Limit'
                                type="number"
                                value={limit}
                                onChange={e => this.setState({ limit: e.target.value })}
                              />
                            </Grid>
                            <Grid className="form-select" item xs={2}>
                              <Button
                                onClick={() => {
                                  this.handleCalendar();
                                }}
                              >
                                <CheckIcon />
                              </Button>
                            </Grid>
                            <Grid className="form-select" item xs={2}>
                              <Button
                                onClick={() => this.setState({ isCalendar: false })}
                              >
                                <ClearIcon />
                              </Button>
                            </Grid>
                        </div>
                      ) : ''
                    }

                    <Grid item xs={12}>
                      {
                        newCalendar?.length > 0 ? (
                          newCalendar?.map((item, index) => (
                            <div className="calendar" key={index}>
                              <Grid className="form-select" item xs={3}>
                                <InputLabel>Date</InputLabel>
                                <TextField
                                  className="form-input"
                                  type="text"
                                  value={this.formatDate(item?.day)}
                                  disabled
                                />
                              </Grid>
                              <Grid className="form-select" item xs={3}>
                                <InputLabel>Hour</InputLabel>
                                <TextField
                                  className="form-input"
                                  type="text"
                                  value={item?.hour}
                                  disabled
                                />
                              </Grid>
                              <Grid className="form-select" item xs={3}>
                                <InputLabel>Limit</InputLabel>
                                <TextField
                                  className="form-input"
                                  type="text"
                                  value={item?.limit}
                                  disabled
                                />
                              </Grid>
                              <Grid className="form-select" item xs={3}>
                                <Button
                                  onClick={() => this.handleRemoveCalendar(index)}
                                >
                                  <RemoveCircleIcon />
                                </Button>
                              </Grid>
                            </div>
                          ))
                        ) : ''
                      }
                    </Grid>
                  </div>
                ) : ''
              }
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <h4>Portuguese</h4>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="form-input"
                  required
                  id="description1" 
                  label='Descrição 1'
                  multiline
                  rows={5}
                  value={description1_pt}
                  onChange={e => this.setState({ description1_pt: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="form-input" 
                  id="description2" 
                  label='Descrição 2'
                  multiline
                  rows={5}
                  value={description2_pt}
                  onChange={e => this.setState({ description2_pt: e.target.value })}
                />
              </Grid>
              <Grid className="form-select" item xs={12}>
                <InputLabel id="select-label">Type</InputLabel>
                <Select
                  className="form-input"
                  required
                  labelId="select-label"
                  id="type"
                  value={type}
                  onChange={e => this.setState({ type: e.target.value })}
                >
                  <MenuItem value={'online'}>Online</MenuItem>
                  <MenuItem value={'face-to-face'}>Face-to-face</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="form-input"
                  required
                  id="price" 
                  label='Price'
                  type="number"
                  value={price}
                  onChange={e => this.setState({ price: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="form-input"
                  required
                  id="duration" 
                  label='Duration(days)'
                  type="number"
                  value={duration}
                  onChange={e => this.setState({ duration: e.target.value })}
                />
              </Grid>
              <Grid className="form-select" item xs={12}>
                <InputLabel id="select-active">Active</InputLabel>
                <Select
                  className="form-input"
                  required
                  labelId="select-active"
                  id="active"
                  value={active}
                  onChange={e => this.setState({ active: e.target.value })}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </Grid>
            </Grid>
           
            <Grid item xs={12}>
              <Button 
                className="btn-save float-r"
                type="submit"
              >
                Save
              </Button>
              <Link 
                className="btn-cancel float-r"
                to='/dashboard'
              >
                Cancel
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>
    )
  };
}

export default EditCourse;